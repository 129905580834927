import {default as _} from "lodash";
import AppStore from "../store";

const rightUtils = {
    rights: {},
    defaultRouteManager: null,
    checkRight: function (actionKey) {
        const rights = _.get(AppStore.getState(), `root.rights`, []);
        let right = false;
        if (actionKey) {
            right = _.find(rights, x => x === actionKey);
        } else {
            right = true;
        }
        return right;
    },
};

export const allRights = {

    /*TENANT*/
    TENANT_ACCESS: "TENANT_ACCESS",
    TENANT_REPORT: "TENANT_REPORT",
    TENANT_VOUCHER_UPDATE: "TENANT_VOUCHER_UPDATE",
    TENANT_CART_UPDATE: "TENANT_CART_UPDATE",
    TENANT_BRANCH_RELATIONSHIP_UPDATE: "TENANT_BRANCH_RELATIONSHIP_UPDATE",
    TENANT_BRANCH_SETUP: "TENANT_BRANCH_SETUP",
    TENANT_BRANCH_EDIT: "TENANT_BRANCH_EDIT",
    TENANT_SERVICE_SETUP: "TENANT_SERVICE_SETUP",
    TENANT_MAP_USER_BRANCH_SETUP: "TENANT_MAP_USER_BRANCH_SETUP",
    TENANT_MAP_USER_BRANCH_EDIT: "TENANT_MAP_USER_BRANCH_EDIT",
    TENANT_RELATION_ACCESS: "TENANT_RELATION_ACCESS",
    TENANT_ROLE_ACCESS: "TENANT_ROLE_ACCESS",
    TENANT_ROLE_EDIT: "TENANT_ROLE_EDIT",
    TENANT_USER_EDIT: "TENANT_USER_EDIT",

    SYSTEM_AI_SUPPORT_ACCESS: "SYSTEM_AI_SUPPORT_ACCESS",

    //appointment
    APPOINTMENT_POS_ACTION: "APPOINTMENT_POS_ACTION",
    APPOINTMENT_ACCESS: "APPOINTMENT_ACCESS",
    APPOINTMENT_TIMESHEET_UPDATE: "APPOINTMENT_TIMESHEET_UPDATE",
    APPOINTMENT_BOOKING_CREATE: "BOOKING_CREATE",
    APPOINTMENT_OVERVIEW: "APPOINTMENT_OVERVIEW",
    TENANT_USER_SETUP: "TENANT_USER_SETUP",
    //role


    /*BOOKING*/
    BOOKING_ACCESS: "BOOKING_ACCESS",
    BOOKING_DELETE: "BOOKING_DELETE",
    OLD_BILL_CANCEL: "OLD_BILL_CANCEL",
    BOOKING_CANCEL: "BOOKING_CANCEL",

    /*REVIEW*/
    REVIEW_ACCESS: "REVIEW_ACCESS",
    REVIEW_UPDATE: "REVIEW_UPDATE",

    /*CUSTOMER*/
    CUSTOMER_ACCESS: "CUSTOMER_ACCESS",
    CUSTOMER_CREATE: "CUSTOMER_CREATE",
    CUSTOMER_DELETE: "CUSTOMER_DELETE",

    /*CART*/
    CART_ACCESS: "CART_ACCESS",
    CART_CREATE: "CART_CREATE",
    CART_DELETE: "CART_DELETE",

    /*GiftCARD*/
    GIFTCARD_ACCESS: "GIFTCARD_ACCESS",
    GIFTCARD_CREATE: "GIFTCARD_CREATE",
    GIFTCARD_DELETE: "GIFTCARD_DELETE",
    GIFTCARD_PAYMENT: "GIFTCARD_PAYMENT",
    GIFTCARD_CREATE_PM: "GIFTCARD_CREATE_PM",

    /*PACKAGE*/
    PACKAGE_ACCESS: "PACKAGE_ACCESS",
    PACKAGE_CREATE: "PACKAGE_CREATE",
    PACKAGE_DELETE: "PACKAGE_DELETE",

    /*PAYMENT*/
    PAYMENTMETHOD_ACCESS: "PAYMENTMETHOD_ACCESS",
    PAYMENTMETHOD_CREATE: "PAYMENTMETHOD_CREATE",
    PAYMENTMETHOD_DELETE: "PAYMENTMETHOD_DELETE",

    /*TECHNICIAN*/
    TECHNICIAN_ACCESS: "TECHNICIAN_ACCESS",
    TECHNICIAN_CREATE: "TECHNICIAN_CREATE",
    TECHNICIAN_DELETE: "TECHNICIAN_DELETE",

    /*TIMESHEET*/
    TIMESHEET_ACCESS: "TIMESHEET_ACCESS",
    TIMESHEET_UPDATE: "TIMESHEET_UPDATE",

    /*REPORT*/
    REPORT_ACCESS: "REPORT_ACCESS",
    REPORT_EXPORT: "REPORT_EXPORT",
    REPORT_NOSHOW: "REPORT_NOSHOW",
    REPORT_DAILY_TRANSACTION_ACCESS: "REPORT_DAILY_TRANSACTION_ACCESS",
    REPORT_REVENUE_ACCESS: "REPORT_REVENUE_ACCESS",
    REPORT_PACKAGE_ACCESS: "REPORT_PACKAGE_ACCESS",
    REPORT_TECHNICIAN_WORKING_ACCESS: "REPORT_TECHNICIAN_WORKING_ACCESS",
    REPORT_MONTH_ACCESS: "REPORT_MONTH_ACCESS",
    REPORT_CUSTOMER_APPOINTMENT_SCHEDULE_ACCESS: "REPORT_CUSTOMER_APPOINTMENT_SCHEDULE_ACCESS",
    REPORT_DAILY_TECHNICIAN_ACCESS: "REPORT_DAILY_TECHNICIAN_ACCESS",
    REPORT_PRODUCT_ACCESS: "REPORT_PRODUCT_ACCESS",
    /*BRANCH*/
    BRANCH_ACCESS: "BRANCH_ACCESS",
    BRANCH_UPDATE: "BRANCH_UPDATE",

    /*SERVICE*/
    SERVICE_ACCESS: "SERVICE_ACCESS",
    SERVICE_CREATE: "SERVICE_CREATE",
    SERVICE_DELETE: "SERVICE_DELETE",

    /*VOUCHER*/
    VOUCHER_ACCESS: "VOUCHER_ACCESS",
    VOUCHER_CREATE: "VOUCHER_CREATE",
    VOUCHER_DELETE: "VOUCHER_DELETE",

    /*PRODUCT*/
    PRODUCT_ACCESS: "PRODUCT_ACCESS",
    PRODUCT_CREATE: "PRODUCT_CREATE",
    PRODUCT_DELETE: "PRODUCT_DELETE",

    /*USER*/
    USER_ACCESS: "USER_ACCESS",
    USER_CREATE: "USER_CREATE",
    USER_DELETE: "USER_DELETE",

    /*TIP*/
    TIP_ACCESS: "TIP_ACCESS",

    /*SYSTEM*/
    // EMAIL TP
    SYSTEM_EMAILTP_ACCESS: "SYSTEM_EMAILTP_ACCESS",
    SYSTEM_EMAILTP_UPDATE: "SYSTEM_EMAILTP_UPDATE",
    //SMS TP
    SYSTEM_SMSTP_ACCESS: "SYSTEM_SMSTP_ACCESS",
    SYSTEM_SMSTP_UPDATE: "SYSTEM_SMSTP_UPDATE",
    //ROLE
    SYSTEM_ROLE_ACCESS: "SYSTEM_ROLE_ACCESS",
    SYSTEM_ROLE_UPDATE: "SYSTEM_ROLE_UPDATE",
    SYSTEM_ROLE_CREATE: "SYSTEM_ROLE_CREATE",
    SYSTEM_ROLE_RIGHT_UPDATE: "SYSTEM_ROLE_RIGHT_UPDATE",
    //BILL TEMPLATE
    SYSTEM_BILLTP_ACCESS: "SYSTEM_BILLTP_ACCESS",
    SYSTEM_BILLTP_UPDATE: "SYSTEM_BILLTP_UPDATE",
    //LOYALTY
    SYSTEM_LOYALTY_ACCESS: "SYSTEM_LOYALTY_ACCESS",
    SYSTEM_LOYALTY_UPDATE: "SYSTEM_LOYALTY_UPDATE",
    //MAP USER
    SYSTEM_MAP_USER_ACCESS: "SYSTEM_MAP_USER_ACCESS",
    SYSTEM_MAP_USER_UPDATE: "SYSTEM_MAP_USER_UPDATE",
    //CHECK OUT METHOD
    SYSTEM_CHECKOUT_METHOD_ACCESS: "SYSTEM_CHECKOUT_METHOD_ACCESS",
    SYSTEM_CHECKOUT_METHOD_UPDATE: "SYSTEM_CHECKOUT_METHOD_UPDATE",
    //LOG ACTION
    SYSTEM_LOG_ACTION_METHOD_ACCESS: "SYSTEM_LOG_ACTION_METHOD_ACCESS",
    //PAYMENT CONFIG
    SYSTEM_PAYMENT_CONFIG_ACCESS: "SYSTEM_PAYMENT_CONFIG_ACCESS",
    SYSTEM_PAYMENT_CONFIG_UPDATE: "SYSTEM_PAYMENT_CONFIG_UPDATE",

    //campagain
    SMS_CAMPAIGN_ACCESS: "SMS_CAMPAIGN_ACCESS",
    SMS_CAMPAIGN_UPDATE: "SMS_CAMPAIGN_UPDATE",

    //PRICE STRATEGY
    PRICE_STRATEGY_ACCESS: "PRICE_STRATEGY_ACCESS",
    PRICE_STRATEGY_UPDATE: "PRICE_STRATEGY_UPDATE",

    //PRICE STRATEGY
    PRICE_STRATEGY_CONDITION_ACCESS: "PRICE_STRATEGY_CONDITION_ACCESS",
    PRICE_STRATEGY_CONDITION_CREATE: "PRICE_STRATEGY_CONDITION_CREATE",
    PRICE_STRATEGY_CONDITION_UPDATE: "PRICE_STRATEGY_CONDITION_UPDATE",

    //TIME CONFIG BOOKING ONLINE
    TIME_CONFIG_BOOKING_ONLINE_ACCESS: "TIME_CONFIG_BOOKING_ONLINE_ACCESS",
    TIME_CONFIG_BOOKING_ONLINE_UPDATE: "TIME_CONFIG_BOOKING_ONLINE_UPDATE",

    //ACTION FLOW
    ACTION_FLOW_ACCESS: "ACTION_FLOW_ACCESS",
    ACTION_FLOW_UPDATE: "ACTION_FLOW_UPDATE",

    //WAREHOUSE MODULE
    WAREHOUSE_ACCESS: "WAREHOUSE_ACCESS",
    WAREHOUSE_CREATE: "WAREHOUSE_CREATE",
    WAREHOUSE_UPDATE: "WAREHOUSE_UPDATE",
    WAREHOUSE_DELETE: "WAREHOUSE_DELETE",

    REQUEST_ACCESS: "REQUEST_ACCESS",
    REQUEST_CREATE: "REQUEST_CREATE",
    REQUEST_UPDATE: "REQUEST_UPDATE",
    REQUEST_DELETE: "REQUEST_DELETE",

    STOCK_ACCESS: "STOCK_ACCESS",

    PRODUCT_WAREHOUSE_ACCESS: "PRODUCT_WAREHOUSE_ACCESS",
    PRODUCT_WAREHOUSE_CREATE: "PRODUCT_WAREHOUSE_CREATE",
    PRODUCT_WAREHOUSE_UPDATE: "PRODUCT_WAREHOUSE_UPDATE",
    PRODUCT_WAREHOUSE_DELETE: "PRODUCT_WAREHOUSE_DELETE",

    CHANGE_WAREHOUSE_ACCESS: "CHANGE_WAREHOUSE_ACCESS",
    CHANGE_WAREHOUSE_CREATE: "CHANGE_WAREHOUSE_CREATE",
    CHANGE_WAREHOUSE_UPDATE: "CHANGE_WAREHOUSE_UPDATE",
    CHANGE_WAREHOUSE_DELETE: "CHANGE_WAREHOUSE_DELETE",

};

export default rightUtils;
