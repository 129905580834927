import loadable from '@loadable/component';
import { ConfigGlobal } from './idtek-component';

ConfigGlobal.setIconMap({
    "video-camera": loadable(() => import('@ant-design/icons/lib/icons/VideoCameraOutlined')),
    "file-pdf": loadable(() => import('@ant-design/icons/lib/icons/FilePdfOutlined')),
    "unordered-list": loadable(() => import('@ant-design/icons/lib/icons/UnorderedListOutlined')),
    "user-switch": loadable(() => import('@ant-design/icons/lib/icons/UserSwitchOutlined')),
    "save": loadable(() => import('@ant-design/icons/lib/icons/SaveOutlined')),
    "close-square": loadable(() => import('@ant-design/icons/lib/icons/CloseSquareOutlined')),
    "lock": loadable(() => import('@ant-design/icons/lib/icons/LockOutlined')),
    "project": loadable(() => import('@ant-design/icons/lib/icons/ProjectOutlined')),
    "file-word": loadable(() => import('@ant-design/icons/lib/icons/FileWordOutlined')),
    "read": loadable(() => import('@ant-design/icons/lib/icons/ReadOutlined')),
    "plus-circle": loadable(() => import('@ant-design/icons/lib/icons/PlusCircleOutlined')),
    "folder-open": loadable(() => import('@ant-design/icons/lib/icons/FolderOpenOutlined')),
    "ordered-list": loadable(() => import('@ant-design/icons/lib/icons/OrderedListOutlined')),
    "property-safety": loadable(() => import('@ant-design/icons/lib/icons/PropertySafetyOutlined')),
    "bell": loadable(() => import('@ant-design/icons/lib/icons/BellOutlined')),
    "desktop": loadable(() => import('@ant-design/icons/lib/icons/DesktopOutlined')),
    "setting": loadable(() => import('@ant-design/icons/lib/icons/SettingOutlined')),
    "appstore": loadable(() => import('@ant-design/icons/lib/icons/AppstoreOutlined')),

    "schedule": loadable(() => import('@ant-design/icons/lib/icons/ScheduleOutlined')),
    "reconciliation": loadable(() => import('@ant-design/icons/lib/icons/ReconciliationOutlined')),
    "pic-right": loadable(() => import('@ant-design/icons/lib/icons/PicRightOutlined')),
    "file-zip": loadable(() => import('@ant-design/icons/lib/icons/FileZipOutlined')),
    "table": loadable(() => import('@ant-design/icons/lib/icons/TableOutlined')),
    "account-book": loadable(() => import('@ant-design/icons/lib/icons/AccountBookOutlined')),
    "bank": loadable(() => import('@ant-design/icons/lib/icons/BankOutlined')),
    "file-excel": loadable(() => import('@ant-design/icons/lib/icons/FileExcelOutlined')),
    "dollar": loadable(() => import('@ant-design/icons/lib/icons/DollarOutlined')),
    "exclamation": loadable(() => import('@ant-design/icons/lib/icons/ExclamationOutlined')),
    "cloud-upload": loadable(() => import('@ant-design/icons/lib/icons/CloudUploadOutlined')),
    "history": loadable(() => import('@ant-design/icons/lib/icons/HistoryOutlined')),
    "paper-clip": loadable(() => import('@ant-design/icons/lib/icons/PaperClipOutlined')),
    "file": loadable(() => import('@ant-design/icons/lib/icons/FileOutlined')),
    "user-add": loadable(() => import('@ant-design/icons/lib/icons/UserAddOutlined')),
    "bar-chart": loadable(() => import('@ant-design/icons/lib/icons/BarChartOutlined')),
    "hdd": loadable(() => import('@ant-design/icons/lib/icons/HddOutlined')),
    "group": loadable(() => import('@ant-design/icons/lib/icons/GroupOutlined')),
    "link": loadable(() => import('@ant-design/icons/lib/icons/LinkOutlined')),
    "retweet": loadable(() => import('@ant-design/icons/lib/icons/RetweetOutlined')),
    "file-add": loadable(() => import('@ant-design/icons/lib/icons/FileAddOutlined')),
    "file-unknown": loadable(() => import('@ant-design/icons/lib/icons/FileUnknownOutlined')),
    "file-ppt": loadable(() => import('@ant-design/icons/lib/icons/FilePptOutlined')),
    "dot-chart": loadable(() => import('@ant-design/icons/lib/icons/DotChartOutlined')),
    "branches": loadable(() => import('@ant-design/icons/lib/icons/BranchesOutlined')),
    "cluster": loadable(() => import('@ant-design/icons/lib/icons/ClusterOutlined')),
    "redo": loadable(() => import('@ant-design/icons/lib/icons/RedoOutlined')),
    "apartment": loadable(() => import('@ant-design/icons/lib/icons/ApartmentOutlined')),
    "select": loadable(() => import('@ant-design/icons/lib/icons/SelectOutlined')),
    "send": loadable(() => import('@ant-design/icons/lib/icons/SendOutlined')),
    "cloud-download": loadable(() => import('@ant-design/icons/lib/icons/CloudDownloadOutlined')),
    "usergroup-add": loadable(() => import('@ant-design/icons/lib/icons/UsergroupAddOutlined')),
    "check-circle-filled": loadable(() => import('@ant-design/icons/lib/icons/CheckCircleFilled')),
    "exclamation-circle-filled": loadable(() => import('@ant-design/icons/lib/icons/ExclamationCircleFilled')),
    "column-width": loadable(() => import('@ant-design/icons/lib/icons/ColumnWidthOutlined')),
    "form": loadable(() => import('@ant-design/icons/lib/icons/FormOutlined')),
    "plus": loadable(() => import('@ant-design/icons/lib/icons/PlusOutlined')),
    "upload": loadable(() => import('@ant-design/icons/lib/icons/UploadOutlined')),
    "calendar": loadable(() => import('@ant-design/icons/lib/icons/CalendarOutlined')),
    "phone": loadable(() => import('@ant-design/icons/lib/icons/PhoneOutlined')),
    "close-circle": loadable(() => import('@ant-design/icons/lib/icons/CloseCircleOutlined')),
    "close": loadable(() => import('@ant-design/icons/lib/icons/CloseOutlined')),
    "shopping-cart": loadable(() => import('@ant-design/icons/lib/icons/ShoppingCartOutlined')),
    "printer": loadable(() => import('@ant-design/icons/lib/icons/PrinterOutlined')),
    "plus-square": loadable(() => import('@ant-design/icons/lib/icons/PlusSquareOutlined')),
    "like": loadable(() => import('@ant-design/icons/lib/icons/LikeOutlined')),
    "man": loadable(() => import('@ant-design/icons/lib/icons/ManOutlined')),
    "woman": loadable(() => import('@ant-design/icons/lib/icons/WomanOutlined')),
    "team": loadable(() => import('@ant-design/icons/lib/icons/TeamOutlined')),
    "info-circle": loadable(() => import('@ant-design/icons/lib/icons/InfoCircleOutlined')),
    "check-circle": loadable(() => import('@ant-design/icons/lib/icons/CheckCircleOutlined')),
    "clock-circle": loadable(() => import('@ant-design/icons/lib/icons/ClockCircleOutlined')),
    "copy": loadable(() => import('@ant-design/icons/lib/icons/CopyOutlined')),
    "rollback": loadable(() => import('@ant-design/icons/lib/icons/RollbackOutlined')),
    "edit": loadable(() => import('@ant-design/icons/lib/icons/EditOutlined')),
    "delete": loadable(() => import('@ant-design/icons/lib/icons/DeleteOutlined')),
    "download": loadable(() => import('@ant-design/icons/lib/icons/DownloadOutlined')),
    "qrcode": loadable(() => import('@ant-design/icons/lib/icons/QrcodeOutlined')),
    "play-square": loadable(() => import('@ant-design/icons/lib/icons/PlaySquareOutlined')),
    "file-search": loadable(() => import('@ant-design/icons/lib/icons/FileSearchOutlined')),
    "credit-card": loadable(() => import('@ant-design/icons/lib/icons/CreditCardOutlined')),
    "minus-square": loadable(() => import('@ant-design/icons/lib/icons/MinusSquareOutlined')),
    "caret-down": loadable(() => import('@ant-design/icons/lib/icons/CaretDownOutlined')),
    "file-done": loadable(() => import('@ant-design/icons/lib/icons/FileDoneOutlined')),
    "caret-right": loadable(() => import('@ant-design/icons/lib/icons/CaretRightOutlined')),
    "carry-out": loadable(() => import('@ant-design/icons/lib/icons/CarryOutOutlined')),
    "money": loadable(() => import('@ant-design/icons/lib/icons/DollarCircleOutlined')),
    "clear": loadable(() => import('@ant-design/icons/lib/icons/ClearOutlined')),
    "out": loadable(() => import('@ant-design/icons/lib/icons/LogoutOutlined')),
    "in": loadable(() => import('@ant-design/icons/lib/icons/LoginOutlined')),
    "filter": loadable(() => import('@ant-design/icons/lib/icons/FilterOutlined')),
    "reconciliationOutlined": loadable(() => import('@ant-design/icons/lib/icons/ReconciliationOutlined')),

});